<template>
  <div class="photosPage">
    <div class="mainContainer">
      <TopMenu :show-logo="true"/>
      <div class="photosNavigatorMap">
        الرئيسية
      </div>

      <form class="filterBar" @submit="filterResults" v-if="!state.isLoadingCats">
        <section>
          <input placeholder="ابحث عن مكان" class="mainInput" name="placeNamesQuery" :value="getFilterValue().placeNamesQuery" type="search"/>
          <button class="searchButton">
            <IconsWorker :width="20" color="#777" icon="searchF"/>
          </button>
        </section>
      </form>


      <div class="filtersAndOrders">

        <div class="photosFilters">
          <div class="section">
            <div v-for="filter in state.ordersByOptions" :class="['filterCell', state.selectedOrderBy === filter.key ? 'selectedFilter' : '']" @click="changeSelectedOrderFilter(filter.key)">
              <span>{{filter.text}}</span>
            </div>
          </div>
        </div>

        <div class="photosFilters">
            <form v-if="!state.isLoadingCats" id="filterForm">
              <div class="selectHolder" style="background: #35005F;" >
                <select name="area" :value="getFilterValue().area" @input="(e) => submitForm(e, 'area')">
                  <option value="">جميع المدن</option>
                  <option :value="cat.value.toString()" v-for="cat in getCatsListToRender('area')">{{ cat.text }}</option>
                </select>
              </div>
              <div class="selectHolder">
                <select name="cat" :value="getFilterValue().cat" @input="(e) => submitForm(e, 'cat')">
                  <option value="">جميع التصنيفات</option>
                  <option :value="cat.value.toString()" v-for="cat in getCatsListToRender('cat')">{{ cat.text }}</option>
                </select>
              </div>
            </form>
        </div>

      </div>



      <DuList type="applys" :exstra-parameters="{...state.exstarParams}" :scroll-body="getScrollBody()" :key="state.listKey">
        <template v-slot:noResults>
          <div class="noResults">
            <IconsWorker icon="search" width="60" color="#ccc"/>
            <span>لم نستطع العثور على أماكن , جرب البحث بكلمة أخرى
            <b> أو ساعدنا في إضافة مكان جديد</b></span>
            <DuButton text="إضافة مكان" @click="showAddDialogAction()"/>
          </div>
        </template>
      </DuList>
    </div>
    <AddOrEditApplyLightBox v-if="state.showAddDialog" :onDone="() => onAddOrEditDone()" :onCancel="() => onCancel()"/>
    <Footer/>
  </div>
</template>

<script>
import TopMenu from "../components/TopMenu";
import {onBeforeMount, onMounted, reactive, watch} from "vue";
import DuList from "../components/DuList";
import Footer from "../components/Footer";
import router from "../router";
import {useRoute} from "vue-router";
import BrowserHelper from "../helpers/BrowserHelper";
import AjaxWorker from "../helpers/AjaxWorker";
import IconsWorker from "../components/IconsWorker";
import DuButton from "../components/DuButton";
import AddOrEditApplyLightBox from "./AddOrEditApplyLightBox";
export default {
  name: "Photos",
  components: {AddOrEditApplyLightBox, DuButton, IconsWorker, Footer, DuList, TopMenu},
  setup() {
    const route = useRoute();
    const state = reactive({
      showAddDialog: false,
      ordersByOptions: [
        { text: 'الأحدث', key: 'newest' },
        { text: 'الأقدم', key: 'oldest' },
        { text: 'الأكثر إعجابًا', key: 'mostRating' },
      ],
      selectedOrderBy: 'newest',
      area: null,
      cat: null,
      exstarParams: {},
      isLoadingCats: true,
      listOfCats: [],
      listKey: `keyList-${Math.random().toLocaleString(16)}`,
    });


    onBeforeMount(() => {
      if(route.query.cat !== undefined) {
        state.cat = route.query.cat;
        state.exstarParams.cat = state.cat;
      }
      if(route.query.area !== 'all' && route.query.area !== undefined) {
        state.area = route.query.area;
        state.exstarParams.area = state.area;
      }

      if(route.query.placeNamesQuery !== undefined && route.query.placeNamesQuery !== '') {
        state.placeNamesQuery = route.query.placeNamesQuery;
        state.exstarParams.placeNamesQuery = state.placeNamesQuery;
      }
      if(route.path.includes('/citySearch')) {
        state.exstarParams.placeNamesQuery = route.query.keyword;
      }
      BrowserHelper.changeTitle('الأماكن');
    });
    const changeSelectedOrderFilter = (key) => {
      state.selectedOrderBy = key;
      state.exstarParams.orderBy = key;
      state.listKey = `keyList-${Math.random().toLocaleString(16)}${key}`;
    }
    const getExstraParams = () => {
      return state.exstarParams;
    }
    const getScrollBody = () => document.getElementsByTagName("body")[0];


    watch(() => route.query, () => {
      delete state.exstarParams['placeNamesQuery'];
      delete state.exstarParams['lastIdFetched'];
      delete state.exstarParams['cat'];
      delete state.exstarParams['area'];
      const _newParams = {};
      Object.keys(route.query).forEach((key) => {

        _newParams[key] = route.query[key];

      });
      state.exstarParams = _newParams;
      //state.listKey = `keyList-${Math.random().toLocaleString(16)}`;
    });

    const filterResults = (e) => {
      const _exstraParams = {...route.query};
      const formData = new FormData(e.target);
      //const area = formData.get('area');
      //const cat = formData.get('cat');
      const placeNamesQuery = formData.get('placeNamesQuery');

      /*
      if(area !== '') {
        _exstraParams.area = area;
      }
      if(cat !== '') {
        _exstraParams.cat = cat;
      }*/
      if(placeNamesQuery !== '') {
        _exstraParams.placeNamesQuery = placeNamesQuery;
      }

      //state.exstarParams = _exstraParams;
      router.push({
        query: _exstraParams,
      });
      e.preventDefault();
      return false;
    }

    const getCats = () => {
      new AjaxWorker().request('/transactions.php?action=getCats&getAll=true').then((res) => {
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          setTimeout(() => {
            getCats();
          },2000);
        } else {
          state.isLoadingCats = false;
          state.listOfCats = res.data;
        }
      });
    }

    onMounted(() => {
      getCats();
    });

    const getCatsListToRender = (type) => {
      const _list = [];
      state.listOfCats.forEach((item) => {
        if(item.type === type) {
          _list.push({
            text: item.title,
            value: item.id,
          });
        }
      });
      return _list;
    }


    const getFilterValue = () => {
      const values = {
        'area': '',
        'cat': '',
        'placeNamesQuery': '',
      };

      if(route.query.area !== undefined && route.query.area !== 'all') {
        values.area = route.query.area.toString();
      }
      if(route.query.cat !== undefined) {
        values.cat = route.query.cat.toString();
      }
      if(route.query.placeNamesQuery !== undefined) {
        values.placeNamesQuery = route.query.placeNamesQuery;
      }

      return values;
    }

    const onAddOrEditDone = () => {
      state.showAddDialog = false;
    }
    const onCancel = () => {
      state.showAddDialog = false;
    }

    const showAddDialogAction = () => {
      state.showAddDialog = true;
    }

    const submitForm = (e, theType) => {

      const _exstraParams = {...route.query};


      if(theType == 'area') {
        _exstraParams.area = e.target.value;
      }
      if(theType == 'cat') {
        _exstraParams.cat = e.target.value;
      }
      router.push({
        query: _exstraParams,
      });
    }

    return {
      state,
      submitForm,
      getCatsListToRender,
      onAddOrEditDone,
      onCancel,
      getExstraParams,
      filterResults,
      getFilterValue,
      getScrollBody,
      showAddDialogAction,
      changeSelectedOrderFilter,
    }
  }
}
</script>

<style scoped>
.photosPage{
  background-color: #000;
  width: 100%;
  background-image: url("../assets/images/bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
}

.photosNavigatorMap{
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #fff;
}

.filtersAndOrders{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.photosFilters{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.photosFilters .section{
  display: flex;
  flex-direction: row;
  gap: 10px;
  background: #35005F;
  border-radius: 10px;
  overflow: hidden;
}
.photosFilters .section div{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.photosFilters .selectedFilter{
  background: #520B73;
}
.photosFilters .section .filterCell{
  padding: 20px 30px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.photosFilters form{
  display: flex;
  flex-direction: row;
  gap: 10px;
  background: #520B73;
  display: flex;
  place-items: center;
  place-content: center;
  overflow: hidden;
  border-radius: 10px;
}
.photosFilters form .selectHolder{
  padding: 0 10px;
  height: 100%;
}
.photosFilters form select{
  font-size: 16px;
  font-weight: bold;
  background: transparent;
  color: #fff;
  border-radius: 0;
  height: 100%;
  width: 160px;
}



.filterBar{
  width: available;
  display: flex;
  flex-direction: row;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  margin: 20px 0;
  gap: 10px;
  align-items: center;
}

.filterBar section{
  background: #f0f0f0;
  width: available;
  display: flex;
  flex-direction: row;
  justify-content: start;
  justify-items: start;
  align-content: start;
  border-radius: 10px 10px 10px 10px;
  flex: 1;
  border: 1px solid #ccc;
}
.filterBar input,select{
  padding: 10px 15px;
  border-radius: 10px;
  border: 0;
  font-size: 16px;
  width: max-content;
  background: transparent;
  flex: 1;
}

.filterBar select{
  cursor: pointer;
}



.filterBar .searchButton{
  cursor: pointer;
  border: 0;
  background: transparent;
}

.mainInput{
  flex: 1;
}

.filterBar .searchButton:hover{ opacity: 0.7; }
.filterBar .searchButton:active{ opacity: 0.4; }

.noResults{
  padding: 50px;
  background: #fff;
  width: 50%;
  place-self: center;
  margin-top: 50px;
  border-radius: 10px;
  box-shadow:  0 0 10px #000;
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  gap: 50px;
  font-size: 17px;
  color: #220046;
  line-height: 25px;
}



@media only screen and (max-width: 600px) {
  .photosPage {
    background-attachment: scroll;
  }

  .photosFilters{
    width: 100%;
  }
  .photosFilters .section{
    align-content: space-between;
    justify-content: space-between;
    width: 100%;
  }
  .photosFilters .section .filterCell{
    font-size: 14px;
    padding: 20px 10px;
    flex: 1;
    text-align: center;
  }



  .filtersAndOrders{
    display: flex;
    flex-direction: column;
  }

  .photosFilters form select{
    width: auto;
  }

}

</style>